<script>
import { getObjectValueByPath } from 'vuetify/lib/util/helpers'

import dataSource from './data-source'

export default {
  props: {
    vin: {
      type: String,
      required: true
    },
    uid: {
      type: Number,
      default: null
    },
    buid: {
      type: Number,
      default: null
    },
    vertical: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    env: {
      type: String,
      default: ''
    }
  },

  computed: {
    listenerId: ({ vin }) => `max-vhr-${vin}`,
    isCarfaxSnapshotLoaded: () => dataSource.isCarfaxSnapshotLoaded,
    environment: ({ env }) => (
      env === 'stage' ||
      ['maxstage.', 'beta.'].some(subdomain => window.location.host.includes(subdomain))
    )
      ? 'stage'
      : null, // defaults to production

    report: ({ vin }) => dataSource.reports[vin] || {},
    hasReport: ({ report }) => Object.keys(report).length > 0,
    snapshotKey: ({ report }) => report.snapshotKey,
    reportUrl: ({ report }) => report.url,
    fourPillars: ({ report }) => report.fourPillars,
    loading: ({ hasReport }) => dataSource.loading && !hasReport,

    apiError: () => dataSource.error,
    mustLogin: ({ report }) => getObjectValueByPath(report, 'error.extensions.code') === 'UNAUTHENTICATED',

    firstLookAPI: ({ environment }) => `https://api.${(environment === 'stage') ? 'maxstage.' : ''}firstlook.biz`,
    loginLink: ({ firstLookAPI, uid }) => `${firstLookAPI}/v1/carfaxconnect/oauth/initiate/${uid}` +
      `?redirect_uri=${encodeURIComponent(window.location)}`,
    loginTarget: () => window !== window.parent ? '_blank' : null,

    isVinInError: ({ apiError, mustLogin, report }) => {
      if (apiError || mustLogin) return false
      return Boolean(report.error)
    }
  },

  watch: {
    isCarfaxSnapshotLoaded: {
      handler (newVal) {
        if (!newVal) return
        this.addListenerToPage()
      },
      immediate: true
    },

    snapshotKey (newVal) {
      if (!newVal) return
      this.addListenerToPage()
    },

    environment: {
      handler () {
        dataSource.configureClient({ env: this.environment })
        dataSource.load(this.vin, this.buid, this.uid)
      },
      immediate: true
    }
  },

  mounted () {
    this.initCarfaxSnapshot()
  },

  methods: {
    initCarfaxSnapshot () {
      // init Carfax snapshot widget only once
      if (document.getElementById('max-vhr-carfax')) return

      ((document, tag, src) => {
        const scriptElement = document.createElement(tag)
        scriptElement.id = 'max-vhr-carfax'
        scriptElement.async = 1
        scriptElement.onload = () => {
          dataSource.isCarfaxSnapshotLoaded = true
        }
        scriptElement.src = src
        document.head.append(scriptElement)
      })(document, 'script', 'https://snapshot.carfax.com/latest/dealer-snapshot.js')
    },

    addListenerToPage () {
      if (!this.snapshotKey || document.getElementById(this.listenerId)) return

      // artificially trigger the event listener
      const div = document.createElement('div')
      div.id = this.listenerId
      div.classList.add('carfax-dealer-snapshot-hover')
      div.dataset.snapshotkey = this.snapshotKey
      const el = document.querySelector(`vehicle-history-report[vin='${this.vin}']`)
      el.parentNode.append(div)

      // Trigger snapshot activation
      // See: https://developer.carfax.com/docs/connect/dealer#dealer-snapshot
      document.dispatchEvent(new Event('refreshCarfaxSnapshotData'))
    },

    triggerSnapshot (event) {
      if (!this.snapshotKey) return
      document.getElementById(this.listenerId).dispatchEvent(new Event(event.type))
    }
  }
}
</script>

<template>
  <div
    v-if="!loading && !apiError && !isVinInError && (!mustLogin || uid)"
    :class="{
      [$style['history-report']]: true,
      [$style.small]: small,
      [$style.vertical]: vertical,
      [$style['has-error']]: mustLogin,
    }"
  >
    <a
      :class="$style['history-report__logo']"
      :href="reportUrl"
      target="_blank"
      rel="noopener noreferrer"
      @mouseover="triggerSnapshot($event)"
      @mouseout="triggerSnapshot($event)"
      @mouseenter="triggerSnapshot($event)"
    >
      <img
        src="https://media.carfax.com/img/cfx/logo.svg"
        alt="CARFAX"
      >
    </a>
    <div
      v-if="mustLogin"
      :class="$style['history-report__message']"
    >
      <a
        :href="loginLink"
        :target="loginTarget"
      >
        Please login to CARFAX
      </a>
    </div>
    <div
      v-else-if="!hasReport"
      :class="$style['history-report__message']"
    >
      No Report Found
    </div>
    <template v-else>
      <div
        :class="$style['history-report__icons']"
      >
        <img
          v-for="({ icon, text }, i) in fourPillars"
          :key="`${i}-${icon.split('/').pop()}`"
          :src="icon"
          :alt="text"
          :title="text"
        >
      </div>
      <div
        v-if="!small"
        :class="$style['history-report__link']"
      >
        <a
          :href="reportUrl"
          target="_blank"
          rel="noopener noreferrer"
          @mouseover="triggerSnapshot($event)"
          @mouseout="triggerSnapshot($event)"
          @mouseenter="triggerSnapshot($event)"
        >View CARFAX Report</a>
      </div>
    </template>
  </div>
</template>

<style lang="scss" module>
  * {
    box-sizing: border-box;
  }

  .history-report {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-family: system-ui;
    font-size: 14px;
    padding: 5px;
    padding-left: 0;
    width: 342px;

    a {
      color: #2085BB;
      line-height: 24px;
      text-decoration: none;
    }

    .history-report__logo {
      line-height: 0;

      img {
        height: 28px;
      }
    }

    .history-report__icons {
      align-items: center;
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      margin-left: 5px;

      img {
        width: 42px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .history-report__link {
      margin-left: 3px;
      text-align: center;
    }

    .history-report__message {
      line-height: 28px;
      padding-left: 6px;
    }

    &.small {
      font-size: 80%;
      padding: 3px 0 0;
      padding-left: 0;
      width: 214px;

      &.has-error {
        width: auto;
      }

      .history-report__logo {
        img {
          height: 18px;
        }
      }

      .history-report__icons {
        img {
          width: 24px;
        }
      }

      .history-report__message {
        align-items: center;
        display: flex;
        height: 18px;
        line-height: 18px;
      }
    }

    &.vertical {
      justify-content: center;
      padding-top: 0;
      width: 196px;

      .history-report__icons {
        margin: 15px 0 5px;
      }

      .history-report__link {
        margin-left: 0;
      }

      &.small {
        width: 160px;

        .history-report__icons {
          justify-content: center;
          margin-bottom: 0;
          margin-top: 5px;

          img {
            margin: -3px 3px 0;
          }
        }
      }

      .history-report__logo {
        margin-top: 10px;
      }

      .history-report__message {
        margin-top: 5px;
        padding-left: 0;
        text-align: center;
      }
    }
  }

</style>
